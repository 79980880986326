import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/components/index'
import projectserves from '@/components/projectserves'
import Solution from '@/components/Solution'
import customerandfriend from '@/components/customerandfriend'
import Companyprofile from '@/components/Companyprofile'
import joinus from '@/components/joinus'
import callme from '@/components/callme'
import developmenthistory from '@/components/developmenthistory'
import productService from '@/components/productService'
import bigdata from '@/components/bigdata'
import things from '@/components/things'
import largescreen from '@/components/largescreen'
import message from '@/components/message'
import basics from '@/components/basics'
import firecontrol from '@/components/firecontrol'
import broadcast from '@/components/broadcast'
import park from '@/components/park'
import charging from '@/components/charging'
import walkie from '@/components/walkie'
import recruit from '@/components/recruit'
import smoke from '@/components/smoke'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // redirect:'/index',
    component: index
  },
  {
    path: '/joinus',
    name: 'joinus',
    component: joinus
  },
  {
    path: '/developmenthistory',
    name: 'developmenthistory',
    component: developmenthistory
  },
  {
    path: '/callme',
    name: 'callme',
    component: callme
  },
  {
    path: '/Companyprofile',
    name: 'Companyprofile',
    component: Companyprofile
  },
  {
    path: '/customerandfriend',
    name: 'customerandfriend',
    component: customerandfriend
  },
  {
    path: '/Solution',
    name: 'Solution',
    component: Solution
  },
  {
    path: '/projectserves',
    name: 'projectserves',
    component: projectserves
  },
  {
    path: '/productService',
    name: 'productService',
    component: productService
  },
  {
    path: '/bigdata',
    name: 'bigdata',
    component: bigdata
  },
  {
    path: '/things',
    name: 'things',
    component: things
  },
  {
    path: '/largescreen',
    name: 'largescreen',
    component: largescreen
  },
  {
    path: '/message',
    name: 'message',
    component: message
  },
  {
    path: '/basics',
    name: 'basics',
    component: basics
  },
  {
    path: '/firecontrol',
    name: 'firecontrol',
    component: firecontrol
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    component: broadcast
  },
  {
    path: '/park',
    name: 'park',
    component: park
  },
  {
    path: '/charging',
    name: 'charging',
    component: charging
  },
  {
    path: '/walkie',
    name: 'walkie',
    component: walkie
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: recruit
  },
  {
    path: '/smoke',
    name: 'smoke',
    component: smoke
  }
]

const router = new VueRouter({
	mode:'history',
  routes
})

export default router
