<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
          <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='关于我们'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div style="  background: rgba(250, 250, 250, 1);">
        <div class="el-main-second">
          <!-- <div>公司简介</div>
          <div>加入我们</div>
          <div>联系我们</div>
          <div>发展历程</div>-->
          <router-link
            :to="item.router"
            v-for="(item,index) in routerLists"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
        </div>
      </div>
      <div style="width:50%;margin:20px auto">
        <span style="font-size:40px;">招聘信息</span>
        <el-divider ></el-divider>
      </div>
     <div  class="cards">
       <div v-for="(item,index) in  positionList" :key="index" v-show="(item.list).length>0" style="margin-bottom:20px">
          <div style="margin:15px;text-align:center;color:#131C73">
             <span style="font-weight:700;font-size:20px">{{item.title}}</span>
          </div>
          <div class="cards2">
              <el-card class="box-card" shadow="hover"  v-for="(items,indexs) in item.list" :key="indexs">
                <div  class="text item">
                <div>
              <div style="font-size:24px;font-weight:700;margin-bottom:10px">{{items.name}}</div>
              <div>
                <!-- <div style="font-size:14px;color:#666">
                  <span>薪资:{{items.salary}}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>年龄要求：{{items.age}}</span>
              </div> -->
              <!-- <div style="font-size:14px;color:#666">
                <p>任职要求</p>
                <p v-for="(itemOrs,indexOrs) in items.ntroduction" :key = 'indexOrs'>
                  {{itemOrs}}
                </p>
              </div> -->
            </div>
          </div>
          <div style="margin:0 0 0 30px">
           <div style="text-align:right">
              <i class="el-icon-s-custom" style="font-size:70px;color:#939393"></i>
           </div>
           <div style="margin-top:60px" @click="recruit(index,indexs)">
             <el-button  plain>查看详情<i class="el-icon-arrow-right el-icon--right"  ></i></el-button>
           </div>
          </div>
          
        </div>
      </el-card>
          </div>
       </div>
  
     </div>
     <div class="cards1">
         <!-- <el-pagination
     
      :page-size="100"
      layout="total, prev, pager, next"
      :total="4"
      background>
    </el-pagination> -->
     </div>
    </el-main>
   <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      tabPosition:'left',
       activeName: 'second',
      msg: "1. 负责TV大屏/手机客户端应用软件开发；",
      msg2: "2. 独立分析和快速排查线上故障，并解决问题；",

      msg3: "3. 编写模块的设计文档，独立完成编码及单元测试；",

      msg4:
        "4. 分析产品设计文档，选择最优的技术方案进行产品模块的框架设计和代码编写",
      msg5: "1.计算机、数学、电子相关专业，大学本科及以上学历；",
      msg6:
        "2.精通Android、iOS应用开发框架至少一种，能独立完成具有复杂业务逻辑和界面效果的应用程序开发；",
      msg7:
        "3.熟悉Web前端开发技术，包括HTML5/CSS3/JavaScript等技术，能够独立进行Web前端开发；",
      msg8: "  4.熟悉C/C++开发、熟悉RCS/IPTV相关业务或有相关开发经验优先；",
      msg9:
        " 5.熟悉服务器端软件设计思想，了解常见的C/S架构数据通讯方案及协议；",
      msg10: " 工作地点：广州",
      routerpath: "",
       routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      routerLists: [
        {
          name: "公司简介",
          index: 1,
          router: "/Companyprofile",
        },
        {
          name: "加入我们",
          index: 2,
          router: "/joinus",
        },
        {
          name: "联系我们",
          index: 3,
          router: "/callme",
        },
        {
          name: "公司资质",
          index: 4,
          router: "/developmenthistory",
        },
      ],
      activeNames: ["1"],
      boxshow: false,
      boxshowsecond: false,
      boxshowthird: false,
      boxshowfour: false,
      positionList:[
          {
            title:'技术类',
            list:[
                {
                name:'Web前端开发工程师',
                salary:'',
                age:'',
                ntroduction:'',
                ze:[
                  '1.负责前端开发，根据产品的需求，完成接口数据交互及功能实现，对公司产品进行开发和维护。',
                  '2.对Web前端新技术进行预研，掌握业内主流技术，保持公司前端开发技术的先进性；',
                  '3.优化与重构前端代码，并整理出可重用的代码模块；',
                  '4.配合产品经理、UI设计师和JAVA程序员，通过各种前端技术手段，提高用户体验并满足性能要求。'
                ],
                yq:[
                  '1.有1年前端工作经验，本科及以上学历，计算机相关专业；',
                  '2.熟悉应用vue.js、AngularJS、H5+ plus框架等。',
                  '3.熟悉应用elementUI、easyUI等框架，有地图及报表等开发经验。',
                  '4.熟悉应用Html5/CSS3/JQuery/JS，有java语言开发基础。',
                  '5.有小程序，公众号，前端页面开发项目经验。',
                  '6.有良好的沟通协作能力和合作意识，优秀的自我学习、快速学习和创新能力，进取心强。',
                  '温馨提示：本岗位工作时需要本人携带个人笔记本电脑。',
                  '温馨提示：本岗位是公司总部直招，岗位性质属于外派驻点广州市天河区陶育路72号中国电信业务支撑中心：广州谐和信息科技发展有限公司与广东省电信规划设计院有限公司长期合作关系'
                ]
              },
              {
                name:'JAVA软件工程师',
                salary:'',
                age:'',
                ntroduction:'',
               ze:[
                  '1.完成项目经理安排的后端java服务开发；',
                  '2.负责应用软件系统开发工作、后台业务系统开发； ',
                  '3.参与研发部门技术评估、技术积累、支持解决技术难题，编写项目技术文档；'
                ],
                yq:[
                  '1.计算机或者相关专业，专科以上学历，2年以上JAVA后台开发经验，掌握面向对象编程思想，具有标准化的代码编写习惯，具有扎实的服务端开发功底；',
                  '2.掌握Spring boot、 Hibernate、 WebServices等，理解并熟练使用缓存。；',
                  '3.熟悉tcp/ip、http等通信协议，具有socket网络编程、企业微信API接口开发经验优先；',
                  '4.熟悉MySQL/oracle/MSSQL等主流数据库之一以上开发，有较强的SQL编写能力；',
                  '5.具有良好的沟通能力和协调能力，具备良好的技术跟踪和创新能力，具备良好的团队精神，能承受工作压力，富有进取心。',
                  '温馨提示：本岗位工作时需要本人携带个人笔记本电脑。',
                  '温馨提示：本岗位是公司总部直招，岗位性质属于外派驻点广州市天河区陶育路72号中国电信业务支撑中心：广州谐和信息科技发展有限公司与广东省电信规划设计院有限公司长期合作关系'
                ]
              },
              {
                name:'中高级电子工程师',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                  '1. 参与对讲机产品通信技术的规划和选型，',
                  '2.硬件通信模块的选型，通信功能优化和技术攻关',
                  '3.负责用户培训、安装系统以及与用户的联络，解答有关产品或项目的技术性问题；',
                  '4.负责整理、书写、提交项目过程中的各种文档；',
                  '5.负责指导和审核项目硬件详细方案的设计评估及模块、器件的选型；',
                  '6. 负责指导及审核原理图及PCB的设计、调试、及配合其他专业工程师的调试；',
                  '7.负责指导及审核硬件测试规范、测试标准和测试方案，设计和完善测试用例，并持续优化改进；'
                ],
                yq:[
                  '1.通信、电子信息等相关专业，全日制大学本科及以上学历，1年以上物联网行业及相似岗位工作经验。',
                  '2.精通4G/WIFI/蓝牙/NB/LTE CAT等无线通信模块技术；',
                  '3.具有扎实的模拟及数字电路专业基础。',
                  '4.具有产品级硬件设计经验，包括可生产性、可测试性及可靠性设计。',
                  '5.乐观开朗、乐于接受挑战，对技术和产品有热情，持续学习，有较好的沟通能力，有团队协作精神。',
                  '6.能接受广州市、泉州市出差。'
                ]
              },
              {
                name:'自动化测试工程师',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                  '1.独立完成BUG定位，协助并推动研发工程师解决BUG，把控软件质量，确保按时交付；',
                  '2.编写测试计划及测试用例，执行在软件生命周期的各个阶段相应的测试；',
                  '3.根据产品特点，编写自动化脚本，制定自动化测试方案，提高测试效率；',
                  '4.根据业务需求制定性能测试方案、性能测试目标和测试策略。'
                ],
                yq:[
                  '1.计算机相关专业本科以上学历，5年以上测试工作经验；',
                  '2.有HTTP接口调试经验优先，有自动化测试工作经验优先；',
                  '3.细致有耐心，责任心强，有良好的语言表达和沟通能力；',
                  '4.熟悉软件测试理论和方法，热爱并致力于软件测试工作，善于观察和对比；',
                  '5.熟练掌握linux命令，熟悉使用mysql/oracle；；',
                  '6.能够熟练应用多种测试工具者优先考虑。'
                ]
              },
            ]
          },
           {
            title:'销售类',
            list:[
              {
                name:'区域销售/渠道商务',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                  '1.协助区域渠内渠道经理推广及销售成交；',
                  '2.负责跟进分管区域内渠道客户经理产品推广情况；',
                  '3.负责协助渠道客户经理解决销售过程中的问题，挖掘业务需求；',
                  '4.协助推广经理产品推广宣讲；',
                  '5.跟进客户订单的下达，并完成订单信息汇总并及时向公司传递订单信息；',
                  '6.督促产品按质按量按期交付，负责应收对账、货款催收等事宜；',
                  '7.负责收集汇总区域内渠道客户经理售后问题，及时反馈给技术服务部，并跟进问题处理。',
                ],
                yq:[
                  '1.学历要求：专科及以上学历，市场营销、工商管理、通信和电子或软件类相关专业。',
                  '2.经验要求：具有一年以上相关电子信息、物联网行业销售或商务跟单工作经验。',
                  '3.具有较强的亲和力和表达能力，较好的气质和谈吐，思维敏捷，具有较高的商务谈判技巧。', 
                  '4.能熟练操作办公软件、会制作PPT培训课件'
                ]
              },
              {
                name:'渠道销售',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                 '1.负责分管区域业务拓展，协助渠道商成交；',
                '2.负责对渠道客户经理业务指导、协助客户经理成交；',
                '3.负责产品推广、策划、技术交流各类活动策划，提高公司影响力；',
                '4.协助部门经理完成推广培训教材开发、修改；',
                '5.负责渠道商落地推广线上、线下训战培训；'
                ],
                yq:[
                  '1.专科以上学历，2年以上工作经验，有电子信息、物联网行业工作经验、有相关电子产品行业售前培训经验者优先考虑；',
                  '2.较强的语言表达能力，协调沟通能力，目标计划能力、执行力； ',
                  '3.对电子电路、物联网通信技术知识有一定的了解；',
                  '4.熟练操作办公软件，会制作PPT培训课件；'
                ]
              },
              {
                name:'区域销售',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                 '1.负责客户开发和市场拓展；', 
                  '2.根据公司市场营销战略，制定行业客户销售计划，完成销售指标；', 
                  '3.建立及维护客户关系，构建行业销售网络； ',
                  '4.总结提炼客户/行业销售方法、销售案例，传播和带动销售团队能力提升。'
                ],
                yq:[
                  '1.大专以上学历，1年以上销售经验；',
                  '2.较强的市场拓展能力，有一定的客户群体和良好的销售业绩； ',
                  '3.思路敏捷，善于捕捉客户需求，具有较强的语言表达能力和交际能力；', 
                  '4.性格乐观、积极进取，能够承受较大的工作压力；', 
                  '5.优秀毕业生可考虑'
                ]
              },
              {
                name:'商务经理',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                 ' 1.负责分管区域业务拓展，协助渠道商成交；',
                '2.负责对渠道客户经理业务指导、协助客户经理成交；',
                '3.负责产品推广、策划、技术交流各类活动策划，提高公司影响力；',
                '4.协助部门经理完成推广培训教材开发、修改；',
                '5.负责渠道商落地推广线上、线下训战培训；',
                ],
                yq:[
                 '1.大专以上学历，1年以上销售经验；',
                '2.较强的市场拓展能力，有一定的客户群体和良好的销售业绩；' ,
                '3.思路敏捷，善于捕捉客户需求，具有较强的语言表达能力和交际能力； ',
                '4.性格乐观、积极进取，能够承受较大的工作压力；', 
                '5.优秀毕业生可考虑。'
                ]
              },
              {
                name:'售前推广经理',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                ' 1.负责分管区域业务拓展，协助渠道商成交；',
                '2.负责对渠道客户经理业务指导、协助客户经理成交；',
                '3.负责产品推广、策划、技术交流各类活动策划，提高公司影响力；',
                '4.协助部门经理完成推广培训教材开发、修改；',
                '5.负责渠道商落地推广线上、线下训战培训；'
                ],
                yq:[
                  '1.本科以上学历，2年以上工作经验，有电子信息、物联网行业工作经验、有相关电子产品行业售前培训经验者优先考虑；',
                  '2.较强的语言表达能力，协调沟通能力，目标计划能力、执行力；  ',
                  '3.对电子电路、物联网通信技术知识有一定的了解；',
                  '4.熟练操作办公软件，会制作PPT培训课件；',
                ]
              },
              {
                name:'市场拓展主管（电单车充电桩）',
                salary:'',
                age:'',
                ntroduction:'',
                 ze:[
                '1.拓展合作渠道、维护及跟进；',
                '2.制定渠道工作计划，规划、组织渠道组市场活动；',
                '3.负责合作社区单位的洽谈与合同谈判及签订，并追踪充电桩安装情况；',
                '4.维护公司稳定客户，保证良好沟通，适时挖掘潜在合作机构，完成二次合作；',
                '5.定期与合作的客户进行沟通，建立良好的、稳定的合作关系；',
                '6.领导交办的其他相关工作。'
                ],
                yq:[
                  '1.专科及以上学历，专业不限；两年以上市场拓展工作经验；',
                  '2.良好的团队协作精神，工作细致认真，能够承担工作压力；',
                  '3.具有较强的市场拓展能力、良好的沟通协调能力和人际关系处理能力；',
                  '4.有充电桩业务拓展经验、物业或社区渠道资源或物业工作经验者优先考虑；',
                  '5.积极主动，性格开朗，形象气质佳。'
                ]
              },
            ]
          },
           {
            title:'职能类',
             list:[]
          },
           {
            title:'运营类',
             list:[]
          },
           {
            title:'客服类',
             list:[]
          }
      ]
    };
  },
  methods: {
    open: function () {
      this.$router.push("/projectserves");
    },
    handleChange(val) {
      console.log(val);
    },
     handleClick(tab, event) {
        console.log(tab, event);
      },
      routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
    // 导航栏点击条状
    handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            },
            // 查看详情
            recruit(index,indexs){
              this.$router.push({path:'/recruit'})
              var arry = [index,indexs]
              sessionStorage.setItem('index',JSON.stringify(arry))
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
    sessionStorage.setItem('positionList',JSON.stringify(this.positionList))
     window.scrollTo(0,500);
    
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background: rgba(242, 246, 252, 1);
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-second {
  width: 770px;
  margin: 0 auto;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: space-around;
}

.el-main-second a:hover {
  color: #00a5e3ff;
}
.el-main-second div:nth-child(1) {
  color: rgba(0, 165, 227, 1);
}
.el-main-second div:hover {
  color: rgba(0, 165, 227, 1);
}

.el-main-third {
  width: 1199px;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 47px;
}
.el-main-third-padding {
  padding-left: 12px;
  padding-top: 17px;
}
.el-main-third-one {
  display: flex;
  font-size: 16px;
  color: rgba(49, 49, 49, 1);
  width: 1162px;
  justify-content: space-between;
}
.el-main-third-one-left {
  width: 94px;
  display: flex;
}

.el-main-third-one-font {
  font-size: 16px;
  color: rgba(49, 49, 49, 1);
  font-weight: bold;
}
.el-main-third-one-img {
  padding-right: 10px;
}

.el-main-third-two {
  margin-top: 19px;
}
.el-main-third-two-font {
  font-size: 14px;
  font-weight: bold;
  color: rgba(49, 49, 49, 1);
}

.el-main-third-two-font-second {
  font-size: 14px;
  font-weight: 400;
  color: rgba(85, 85, 85, 1);
  margin-top: 15px;
  /* margin-bottom: 22px; */
}

.nav-second {
  border-top: 2px solid rgba(242, 246, 252, 1);
  height: 88px;

  line-height: 88px;
}

.nav-third {
  border-top: 2px solid rgba(242, 246, 252, 1);
  height: 88px;

  line-height: 88px;
}
.nav-four {
  border-top: 2px solid rgba(242, 246, 252, 1);
  height: 88px;

  line-height: 88px;
}

// .el-collapse-item__header{
//   height: 88px !important;
//   line-height: 88px !important;
// }

.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .cards{
    width:50%;
    margin:0 auto;
  }
  .cards2 {
    display: flex;
    flex-wrap: wrap;
     width:100%;
     min-height: 500px
  }
   .cards1{
    display: flex;
    width:50%;
    margin:20px auto;
    justify-content: center
  }
  .el-card {
    flex: 1;
width: 46%;
min-width: 46%;
max-width: 46%;
margin: 10px
  }
  .text {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  .el-card:hover .el-button{
    background: #131C73;
    color: #fff;
    border-color: #131C73;
  }
  .el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   