<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
           <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='关于我们'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div style="  background: rgba(250, 250, 250, 1);">
        <div class="el-main-second">
          <!-- <div>公司简介</div>
          <div>加入我们</div>
          <div>联系我们</div>
          <div>发展历程</div>-->
          <router-link
            :to="item.router"
            v-for="(item,index) in routerLists"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
        </div>
      </div>
      <div class="el-main-third">
        <div class="el-main-third-one">
          <img src="@/assets/关于我们/联系我们.png" alt />
        </div>
        <div class="el-main-third-two">
          <div class="el-main-third-two-item">
            <img src="@/assets/关于我们/地址.png" style="margin-right:9px" alt />
            <div>{{msg}}{{msg1}}</div>
          </div>
          <div class="el-main-third-two-item">
            <img src="@/assets/关于我们/电话.png" style="margin-right:9px" alt />
            <div>{{msg2}}{{msg3}}</div>
          </div>
          <div class="el-main-third-two-item">
            <img src="@/assets/关于我们/邮箱.png" style="margin-right:9px" alt />
            <div>{{msg4}}{{msg5}}</div>
          </div>
          
        </div>
        <div class="el-main-third-third">
        </div>
        <div class="el-main-third-four">
          <!-- <img src="@/assets/关于我们/图片3.png" alt /> -->
          <baidu-map
            id="allmap"
            @ready="mapReady"
            :scroll-wheel-zoom="true" >
             <bm-marker :position="point" animation="BMAP_ANIMATION_BOUNCE">
            </bm-marker>
        </baidu-map>
        </div>
        <!-- <div class="el-main-third-five">{{msg2}}</div> -->
      </div>
    </el-main>
   <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      point: "",
      msg: "公司地址：",
      msg1: "广东广州市天河区翰景路一号16楼1601",
      msg2: "联系电话：",
      msg3: "020-38456006",
      msg4: "商务合作邮箱：",
      msg5: "xiehe@wjxjt.com",
      routerpath: "",
      routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      routerLists: [
        {
          name: "公司简介",
          index: 1,
          router: "/Companyprofile",
        },
        {
          name: "加入我们",
          index: 2,
          router: "/joinus",
        },
        {
          name: "联系我们",
          index: 3,
          router: "/callme",
        },
        {
          name: "公司资质",
          index: 4,
          router: "/developmenthistory",
        },
      ],
    };
  },
  methods: {
     mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.353812,23.149774);
      map.centerAndZoom(this.point, 19);
      
    },
    open: function () {
      this.$router.push("/projectserves");
    },
      routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
    handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
    
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-second {
  width: 770px;
  margin: 0 auto;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: space-around;
}

.el-main-second a:hover {
  color: #00a5e3ff;
}
.el-main-second div:nth-child(1) {
  color: rgba(0, 165, 227, 1);
}
.el-main-second div:hover {
  color: rgba(0, 165, 227, 1);
}

.el-main-third {
  margin: 0 auto;
  width: 1171px;
  margin-top: 66px;
}

.el-main-third-two {
  padding-top: 35px;
  padding-bottom: 36px;
  height: 175px;
  font-size: 18px;
  color: rgba(115, 115, 115, 1);
}

.el-main-third-two-item {
  display: flex;
  padding-bottom: 30px;
}
.el-main-third-third {
  display: flex;
  /* width: 1133px; */
  justify-content: space-around;
  padding-bottom: 29px;
}

.el-main-third-four {
  font-size: 24px;
  color: rgba(49, 49, 49, 1);
  padding-bottom: 80px;
}
.el-main-third-five {
  height: 80px;
  font-size: 18px;
  color: rgba(115, 115, 115, 1);
  padding-bottom: 96px;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }

#allmap{
  height: 600px;
  width: 100%;
  margin: 0 auto;
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   