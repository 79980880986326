<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='解决方案'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
               <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <el-divider>智能烟感</el-divider>
      </div>
       <div class="bigImg2">
          <div class="letfImg">
              <img src="@/assets/f14.png" alt="" style="width:700px">
          </div>
            <div>
               <img src="@/assets/f15.png" alt="" style="width:700px">
            </div>
      </div> 
        <div class="bigImg">
          <div class="letfImg">
              <img src="@/assets/f17.png" alt="">
          </div>
          <div class="bigimgs bigBox" >
               <div class="bigImg1s" >
                <div style="color:#00A3E5;font-size:20px;font-weight:700;text-align:center">产品特点</div>
            </div> 
                <div style="margin:20px 10px">
                    <i class="el-icon-check" style="margin-right:10px;color:red"></i>
                     <span style="font-weight:700;color:red">TTS技术即时通知</span>
                    接到报警信号直接拨打多个绑定的电话通知火警发生的精确位置，并进行声光电报警
                </div>
                <div style="margin:20px 10px">
                      <i class="el-icon-check" style="margin-right:10px;color:red"></i>
                <span style="font-weight:700;color:red">自我诊断</span>
                   在线上报设备的工作状态和电量，设备故障或低电量都会通知

                </div>
                <div style="margin:20px 10px">
                      <i class="el-icon-check" style="margin-right:10px;color:red"></i>
                <span style="font-weight:700;color:red">平台联动</span>
                    接驳用户现有监控平台反映设备运行状况和具体的历史报警记录与信息
                </div>
                <div style="margin:20px 10px">
                     <i class="el-icon-check" style="margin-right:10px;color:red"></i>
                <span style="font-weight:700;color:red">安装简便</span>
                    使用锂电池供电长达3-5年，无须布线或设置信号转接设备，单个设备即插即用

                </div>
          </div>
      </div> 
      <div class="bigImgs" >
         <div class="bigImg1s" style="font-size:16px;text-align:center;width:300px;margin-right:10px">
              <div style="margin-bottom:10px;padding:5px 0" class="bigBox">
              <span>
                  <p style="color:#0070C1;font-weight:700">设备尺寸:</p>
                <P>
                     直径：127mm
                </P>
                <P>
                    厚：41.3mm
                </P>
              </span>
          </div>
         <div style="background:#CCDEF7;padding:5px 0">
              <span>
                  <span style="color:#0070C1;font-weight:700">
                       网络制式：
                  </span>
                NB-IOT
              </span>
          </div>
         </div>
          <div class="bigImg1s" style="font-size:16px;width:300px;text-align:center;margin-right:10px">
              <div style="margin-bottom:10px;padding:5px 0" class="bigBox">
              <span>
                  <p style="color:#0070C1;font-weight:700">平台支持:</p>
                <P>
                     手机APP
                </P>
                <P>
                    PC客户端
                </P>
              </span>
          </div>
         <div style="background:#CCDEF7;padding:5px 0">
              <span>
                 <span>
                     <span style="color:#0070C1;font-weight:700">
                        是否插卡：
                  </span>
                    </span>是
              </span>
          </div>
         </div>
         <div class="bigImg1s" style="font-size:16px;text-align:center;width:300px;margin-right:10px">
              <div style="padding:5px 0" class="bigBox">
              <span>
                  <p style="color:#0070C1;font-weight:700">应用领域:</p>
                <P>
                     出租房、工棚
                </P>
                <P>
                   沿街商铺
                </P>
                <p>
                    九小场所
                </p>
                <p>
                    古建筑
                </p>
                <p>
                    办公楼
                </p>
              </span>
          </div>
         </div>
          <div class="bigImg1s" style="font-size:16px;">
              <div style="padding:5px 0;height:100%;padding-left:20px" class="bigBox">
              <span>
                  <p style="color:#0070C1;font-weight:700">应用案例:</p>
                <P>
                    目前在江苏省内集团客户做重点推广，在浙江、江苏、山东境内有小批量试点。
                </P>
              </span>
          </div>
         </div>
      </div>
      
    </el-main>
  <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
          footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
          routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
        equipmentList:[
            {
                model:'CH-D-0.3KVA',
                power:'0.3',
                voltage:'DC24/36V',
                size:'400*500*125',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-0.6KVA',
                power:'0.6',
                voltage:'DC24/36V',
                size:'400*500*125',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-1.0KVA',
                power:'1.0',
                voltage:'DC24/36V',
                size:'400*500*125',
                Installation:'挂墙安装'
            }
        ],
        powerSupply:[
             {
                model:'CH-D-0.15KVA',
                power:'0.15',
                voltage:'DC24/36V',
                size:'500*650*150',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-0.3KVA',
                power:'0.3',
                voltage:'DC24/36V',
                size:'500*700*200',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-0.4KVA',
                power:'0.4',
                voltage:'DC24/36V',
                size:'500*700*200',
                Installation:'挂墙安装'
            }
        ],
          powerSupply1:[
             {
                model:'CH-D-0.6KVA',
                power:'0.6',
                voltage:'DC24/36V',
                size:'500*700*200',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-0.8KVA',
                power:'0.8',
                voltage:'DC24/36V',
                size:'500*700*200',
                Installation:'挂墙安装'
            },
              {
                model:'CH-D-1.0KVA',
                power:'1.0',
                voltage:'DC24/36V',
                size:'500*700*200',
                Installation:'挂墙安装'
            }
        ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
    handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 40px
}

.el-divider__text{
  font-size: 35px;
  background: #fff;
}
.el-main_second1 {
    width: 500px;
}
.el-main_second2 {
    width: 700px
}
.el-main_second2 .el-divider__text {
    font-size: 18px;
  background: #fff;
  font-weight: bold
}
.el-main_second1 .el-divider__text {
    font-size: 18px;
  background: #fff;
  font-weight: bold
}

.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px
}
.bigImg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px
}
.bigImgs {
       width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.bigImg2 {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column
}
.bigImg2 div {
    margin: 5px
}
.orderImg .el-row {
    display: flex;
    justify-content: center
}
.bigImg .imgF {
    width: 70%
}
.bigImg1 {
    width: 70%;
    margin: 0 auto;
    font-size: 20px
}
.bigImg1s {
    width: 70%;
    margin: 0 auto;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.bigImg2 {
    width: 70%;
    margin: 0 auto;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.bottomfg {
    font-weight: bold;
    color: #131C73
}


  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card div {
      margin: 20px;
      font-size: 18px
  }

  .time {
    
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    font-size: 13px;
    color: #999;
  }
.bottom div{
    margin: 10px
}
  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
    height: 270px;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .letfImg img {
      width: 300px;
      display: block
  }
  .textDe div {
      margin: 30px;
      width: 500px
  }
  .bigBox {
   background: -webkit-linear-gradient(to top,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to top,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to top,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to top,#B7D0F4,#fff); /* 标准的语法（必须放在最后） */
  }
  .el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   