<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
          <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='关于我们'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div style="  background: rgba(250, 250, 250, 1);">
        <div class="el-main-second">
          <router-link
            :to="item.router"
            v-for="(item,index) in routerLists"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
          <!-- <div  ></div>
          <div>公司简介</div>
          <div>加入我们</div>
          <div>联系我们</div>
          <div>发展历程</div>-->
        </div>
      </div>
      <div class="el-main-third">
        <div class="el-main-third-one">
          <img src="@/assets/关于我们/公司简介.png" alt />
        </div>
        <div class="el-main-third-two">
         谐和科技成立于2006年，公司坐落位于广州天河科技产业园区，是国内领先的移动电信增值服务提供商、信息化解决方案提供商，专注为政府、企业、社区等提供专业、智能、稳定可靠的通信增值业务服务和行业信息化解决方案。
          <p>{{msg}}</p>
           <p>{{msg1}}</p>
        </div>
        <div>
          <img src="@/assets/关于我们/图片1.png" alt="" style="margin-right:30px">
          <img src="@/assets/关于我们/图片2.png" alt="">
        </div>
        <div class="el-main-third-four">公司定位</div>
        <div class="el-main-third-five">{{msg2}}</div>
        <div class="el-main-third-third">
          <div class="el-main-third-third-img">
            <img src="@/assets/关于我们/5.png" alt />
            <!-- <img src="@/assets/关于我们/9.png" alt /> -->
          </div>
          <div class="el-main-third-third-img-second">
            <img src="@/assets/关于我们/6.png" alt />
            <!-- <img src="@/assets/关于我们/10.png" alt /> -->
          </div>
          <div class="el-main-third-third-img-third">
            <img src="@/assets/关于我们/7.png" alt />
            <!-- <img src="@/assets/关于我们/11.png" alt /> -->
          </div>
          <div class="el-main-third-third-img-four">
            <img src="@/assets/关于我们/8.png" alt />
            <!-- <img src="@/assets/关于我们/12.png" alt /> -->
          </div>
        </div>
        <div class="el-main-third-four">目标客群</div>
         <div class="el-main-third-five">{{msg3}}</div>
      </div>
    </el-main>
      <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      msg:
        "公司拥有国家工业和信息化部批准的跨地区增值业务经营资质，是中国移动、电信、联通三大运营商的核心战略合作伙 伴，业务范围覆盖全国，已为不同行业数万家单位提供各大运营商融合的智能短信、智能语音、流量运营等增值电信业务。 近年来，重点打造了大数据管理平台、物联网管理平台、云通信平台，建立了通信模组、数字化终端的研发、设计和制造能 力，为企业客户提供云-管-端的物联网整体解决方案。",
      msg2:
        "企业目标是成为领先的智能物联及增值通信服务解决方案商。以BtoB、 BtoG模式为企业客户和政府机构提供全流程、模块化的综合智慧物联科技解决方案。包括基础智慧物联平台，大数据汇聚及分析平台，短信服务平台 产品 ，客户画像数据服务平台以及智慧城市、智慧园区、智慧社区一体化交付能力等。",
      msg1:'公司是国家高新技术企业，并先后获得了广州市科技小巨人，高新培育补贴等多项政府专项支持，拥有多项软件著作权， 拥有专业的移动互联网技术研发团队，在移动互联网的云平台、大数据、物联网等方面有领先的技术能力，尤其在平安乡村、 智慧社区、智慧消防等领域拥有独特的技术优势和行业资源，具有成熟的解决方案案例。',
      routerpath: "",
      msg3:'政府、公共事业、运营商、房地产、园区、商业体等',
     routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      routerLists: [
        {
          name: "公司简介",
          index: 1,
          router: "/Companyprofile",
        },
        {
          name: "加入我们",
          index: 2,
          router: "/joinus",
        },
        {
          name: "联系我们",
          index: 3,
          router: "/callme",
        },
        {
          name: "公司资质",
          index: 4,
          router: "/developmenthistory",
        },
      ]
    };
  },
  methods: {
    open: function () {
      this.$router.push("/projectserves");
    },
      routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
    handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-second {
  width: 770px;
  margin: 0 auto;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: space-around;
}
.el-main-second a:hover {
  color: #00a5e3ff;
}
.el-main-second div:nth-child(1) {
  color: rgba(0, 165, 227, 1);
}
.el-main-second div:hover {
  color: rgba(0, 165, 227, 1);
}

.el-main-third {
  margin: 0 auto;
  width: 1198px;
  margin-top: 60px;
}

.el-main-third-two {
  padding-top: 31px;
  padding-bottom: 39px;
  min-height: 175px;
  font-size: 18px;
  color: rgba(115, 115, 115, 1);
  text-indent: 2em
}
.el-main-third-two p {
margin-top: 5px;
margin-bottom: 5px
}
.el-main-third-third {
  display: flex;
  width: 1133px;
  justify-content: space-around;
  padding-bottom: 29px;
}

.el-main-third-third-img:hover{
 content: url("../assets/关于我们/9.png") !important;
}

.el-main-third-third-img-second:hover{
   content: url("../assets/关于我们/10.png") !important;
}
.el-main-third-third-img-third:hover{
   content: url("../assets/关于我们/11.png") !important;
}
.el-main-third-third-img-four:hover{
   content: url("../assets/关于我们/12.png") !important;
}


.el-main-third-four {
  font-size: 24px;
  color: rgba(49, 49, 49, 1);
  padding-bottom: 24px;
  margin-top: 20px;
  font-weight: bold
}
.el-main-third-five {
  height: 80px;
  font-size: 18px;
  color: rgba(115, 115, 115, 1);
  padding-bottom: 19px;
  text-indent: 2em
}
.el-main-third-six {
 width: 100%;
display: flex;
flex-wrap: wrap;
}
.el-main-third-six-advantage {
flex: 1;
width: 50%;
min-width: 50%;
max-width: 50%;
}
.el-main-third-six-box {
  width: 80%;
  margin: 0 auto;
}
.el-main-third-six-box-title {
  text-align: center;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  padding: 5px 0;
  color: #fff;
  font-size: 22px
}
.el-main-third-six-box-content {
  margin: 20px;
  font-size: 18px;
  padding: 0 20px
}
.el-main-third-six-box-content p {
  margin: 10px 0
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.strongWord {
 
  font-weight: bold
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   