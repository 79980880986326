<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
           <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='关于我们'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
               <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div style="  background: rgba(250, 250, 250, 1);">
        <div class="el-main-second">
          <!-- <div>公司简介</div>
          <div>加入我们</div>
          <div>联系我们</div>
          <div>发展历程</div>-->
          <router-link
            :to="item.router"
            v-for="(item,index) in routerLists"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
        </div>
      </div>
      <div class="el-main-four">
        <div>
          <div style="color:#00A3E5;font-size:18px;font-weight:700">公司资质</div>
          <div style="  display: flex;justify-content: center">
            <div style="padding-left:15px;padding-top:20px;margin-right:10%;width:34%;">
              <p>1、公司的资质齐全，具备电信通信行业认可资质<span style="font-weight:700">《增值电信业务经营许可证》</span>。</p>
              <p>2、作为通信服务商，严格遵守国家制定的各项法律法规，接受各部门监督与管理。</p>
            </div>
            <div style="width:40%">
              <img src="@/assets/gs.png" alt="" style="width:100%">
            </div>
          </div>
        </div>
      </div>
      <div class="el-main-four" style="justify-content:space-around">
        <div class="el-main-four-item" v-for="(item,index) in picList" :key="index">
          <div>
             <el-image 
             :src="require('@/assets/关于我们/'+item.pic+'.jpg')" style='width:235px;height:321px'>
          </el-image >
          <div style="text-align:center">
              {{item.name}}
          </div>
          </div>
        </div>
      </div>
        <div class="el-main-four">
        <div>
          <div style="color:#00A3E5;font-size:18px;font-weight:700">公司荣誉证书</div>
          <div style="  display: flex;justify-content: center">
            <div style="padding-left:15px;padding-top:20px;margin-right:10%;width:35%" >
                <img src="@/assets/43.png" alt="" style="width:100%">
            </div>
             <div style="padding-left:15px;padding-top:20px;width:35%">
                <img src="@/assets/44.png" alt="" width="100%">
            </div>
          </div>
        </div>
      </div>
    </el-main>
   <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      msg: "公司地址：",
      msg1: "广东广州市越秀区环市东路388号",
      msg2: "联系电话：",
      msg3: "020-37618343",
      msg4: "商务合作邮箱：",
      msg5: "pxkc@cingov.com.cn",
      msg6: "商务合作联系人：",
      msg7: "李军",
      routerpath: "",
      routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      routerLists: [
        {
          name: "公司简介",
          index: 1,
          router: "/Companyprofile",
        },
        {
          name: "加入我们",
          index: 2,
          router: "/joinus",
        },
        {
          name: "联系我们",
          index: 3,
          router: "/callme",
        },
        {
          name: "公司资质",
          index: 4,
          router: "/developmenthistory",
        },
      ],
      picList:[
         {
           pic:'31',
           name:'短信短信接口平台V1.0'
         },
         {
           pic:'32',
           name:'多商户话费充值系统V1.0'
         },
         {
           pic:'33',
           name:'国际短信平台软件V1.0'
         },
         {
           pic:'34',
           name:'微社区微信平台V1.0'
         },
         {
           pic:'35',
           name:'谐和B2B销售业务管理系统V1.0'
         },
         {
           pic:'36',
           name:'谐和代理商接口平台V1.0'
         },
         {
           pic:'37',
           name:'谐和机房运行配置管理平台软件V1.0'
         },
         {
           pic:'38',
           name:'谐和实时监控服务平台V1.0'
         },
         {
           pic:'39',
           name:'谐和验证码产品代理商服务软件V1.1'
         },
         {
           pic:'40',
           name:'易流量管理软件'
         },
         {
           pic:'41',
           name:'语音验证码平台软件V1.0'
         }
      ]
    };
  },
  methods: {
    open: function () {
      this.$router.push("/projectserves");
    },
     routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
     handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-second {
  width: 770px;
  margin: 0 auto;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: space-around;
}

.el-main-second a:hover {
  color: #00a5e3ff;
}

.el-main-second div:nth-child(1) {
  color: rgba(0, 165, 227, 1);
}
.el-main-second div:hover {
  color: rgba(0, 165, 227, 1);
}

.el-main-third {
  margin: 0 auto;
  width: 820px;
  margin-top: 66px;
  padding-right: 354px;
  padding-bottom: 60px;
}

.el-main-third-two {
  display: flex;
  justify-content: space-between;
  /* flex-flow: wrap; */
  margin-top: 30px;
  /* align-items:center; */
  font-size: 18px;
  color: rgba(115, 115, 115, 1);
}
.el-main-third-two :after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.el-main-third-two-left {
  display: flex;
  flex-direction: column;
  height: 193px;
  justify-content: space-between;
}

.el-main-third-two-left div {
  height: 26px;
  /* text-align: center; */
}
.el-main-third-two div {
  display: flex;
  flex-direction: column;
  height: 193px;
}
.el-main-four {
  margin: 0 auto;
  width: 1122px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  padding-bottom:50px;
  padding-top: 50px
}
/* .el-main-four:last-child{

} */
.el-main-four-item {
  width: 21%;
  padding-bottom: 30px;
}

.el-main-four-item img {
  width: 210px;
  display: block;
}
/* .el-main-four img {
  width: 210px;
  display: block;
   width: 20%; 
  padding-bottom: 30px;
}  */
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   