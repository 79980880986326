<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='解决方案'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
               <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <el-divider>智能充电桩</el-divider>
      </div>
        <div class="bigImg" style="margin-bottom:80px">
             <div style="text-indent:2em">
                 <div>
                         针对电动自行车充电安全问题，我司与合作伙伴研发了一套 <span style="color:#1A6D8C;font-weight:700"> 智能化、可运营、“互联网+”</span> 的城市级安全充电解决方案，集智能充电终端、移动物联专网、充电运营管理云平台、用户端App（iOS/安卓/微信端）。

                 </div>
                 <div>
                     可广泛适用于 <span style="color:#1A6D8C;font-weight:700"> 共享充电、智慧社区、智慧城市、工厂园区</span>等场景和领域，为广大电动车主提供更安全、更智能、更便捷的充电服务。
                 </div>
           </div>
           <img src="@/assets/c.png" alt="" style="width:70%">
           
        </div>
          
      
         <div class="bigImg"  >
             <div>运营管理云平台提供完善的充电管理后台，实现终端设备的远程集中管控和充电行为的安全管理，让运营管理更加高效</div>
        </div>
          <div class="bigImg" >
             <img src="@/assets/c1.png" alt="">
         </div>
          <div class="bigImg"  >
             <div>
                 <span style="color:#1A6D8C;font-weight:700"> 管家APP</span>
                 是运营管理云平台专用的移动平台，为运营公司提供
                 <span style="color:#1A6D8C;font-weight:700">  充电站及设备管理、用户体系管理、充电订单管理、运营数据管理、财务结算管理、异常告警管理等</span>
                 管理功能
             </div>
        </div>
          <div class="bigImg" >
             <img src="@/assets/c4.png" alt="">
         </div>
          <div class="bigImg"  >
             <div>
                 为用户提供手机 <span style="color:#1A6D8C;font-weight:700"> APP和微信小程序、微信公众号</span>等多种使用方式；同时，
                   <span style="color:#1A6D8C;font-weight:700">   支持微信、支付宝等主流移动支付工具，</span>
                 通过简单的几个步骤，即可进行充电，让用户体验高效、安全的充电服务
             </div>
        </div>
          <div class="bigImg" >
             <img src="@/assets/c5.png" alt="">
         </div>
          <div class="el-main_second">
        <el-divider>方案优势</el-divider>
      </div>
           <div class="bigImg" style="margin-bottom:80px">
           <img src="@/assets/c7.png" alt="" style="width:900px">
        </div>
    </el-main>
  <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
       routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
    things:[
        {
            title:'云端监控',
            text:'通过设备列表、监控地图等，对设备进行全方位的管理',
            pic:'t1'
        },
           {
            title:'智能管理',
            text:'平台部署后，全部设备都可通过独创AMS智能运维技术实现自动监控和管理，实现企业物联网自动化管理。',
             pic:'t2'
        },   {
            title:'数据分析',
            text:'流量、费用、子账户数据、设备状态、定位等全方位多维度数据分析和展示。',
             pic:'t3'
        },   {
            title:'高效传输',
            text:'支持三大运营商的NB-IOT高效传输协议。',
             pic:'t4'
        },   {
            title:'安全稳定',
            text:'三大运营商移动网络支持，专业服务保障',
             pic:'t5'
        }
    ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
     handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 40px
}
.el-divider__text{
  font-size: 35px;
  background: #fff;
}

.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px
}
.bigImg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 50px
}
.square {
    width: 150px;
    height: 150px;
    background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
    border-radius: 25px;
    margin-left:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.square img{
    width: 100px;
    height: 100px;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px
}
.flexText {
    padding: 0 20px
}
.flexText div {
    margin: 10px;
    width: 150px;
}
.textBox div {
    margin: 10px
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   