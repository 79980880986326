<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='产品落地服务'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <div>产品落地服务</div>
      </div>
      <div class="el-main_tir" style="margin-bottom:80px">
        <div>
          {{msg}}
        </div>
        <div style=" position: relative;">
          <div class="ring">
             <span> 技术方案团队</span>
          </div>
           <div class="ring1">
             <span> 产品推广团队</span>
          </div>
            <div class="ring2">
             <span> 跟单团队</span>
          </div>
           <div class="ring3">
             <span> 客服团队</span>
          </div>
           <div class="ring4">
             <span> 装维团队</span>
          </div>
        </div>
      </div>
      <div>
        <div style="background:#131C73;padding:10px 0">
        <div class="el-main_second">
        <div style="color:#fff">服务优势</div>
      </div>
      <div class="el-main_tir1">
      <div>成熟的物联网技术平台及行业解决方案</div>
      <div>通过训战不断提高产品服务水平</div>
      <div>完整的一站式产品落地服务团队</div>
      </div>
        <div class="el-main_tir2" style="margin-bottom:80px">
      <div>与各大运营商都保持良好的合作关系</div>
      <div>具体覆盖全国的销售网络</div>
      </div>
      </div>
       <div class="el-main_second" style="margin-bottom:50px">
        <div>服务过程</div>
      </div>
      <div class="el-main_tir4" style="margin-bottom:80px">
         <el-timeline>
    <el-timeline-item  :timestamp="item.title" placement="top" v-for="(item,index) in processList" :key='index' size='large' color='#0A6688'>
      <el-card>
       
        <p>{{item.content}}</p>
      </el-card>
    </el-timeline-item>
  </el-timeline>
       <!-- <el-steps :active="5" align-center direction="vertical" >
  <el-step title="正式授权" description="明确各方的责任、权、利，没有完整、清晰的组织架构项目，一定会变成一锅粥" space='20'></el-step>
  <el-step title="管理预期" description="面向客户、管理层以及运营（销售）的预期管理，明确产品方向，确定需求范围"></el-step>
  <el-step title="风险预警" description="充分的分析、预报风险，并合理的引导整个团队如何正确的应对风险，制定有效的风险管理策略"></el-step>
   <el-step title="制定规范" description="基本的产品原则和项目规范的产品成功的基础条件，且必须在正式的场合全体的一致理解"></el-step>
  <el-step title="明确计划" description="合理、有效的拆分项目任务，制定清晰的计划与团队达成一致，确保计划被真正执行"></el-step>
</el-steps> -->
      </div>
      </div>
    </el-main>
   <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
      msg:'我们拥有专业的营销策划和产品推广能力，依托不断成熟的物联网基础平台及行业解决方案，为各专业品牌厂家及其他硬件生产厂家提供物联网产品落地服务和渠道落地服务，通过我们的跟单团队、客服团队、装维团队为企业提供360°一站式专业服务。',
          footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
          routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      processList:[
        {
          title:'正式授权',
          content:'明确各方的责任、权、利，没有完整、清晰的组织架构项目，一定会变成一锅粥'
        },
         {
          title:'管理预期',
          content:'面向客户、管理层以及运营（销售）的预期管理，明确产品方向，确定需求范围'
        },
         {
          title:'风险预警',
          content:'充分的分析、预报风险，并合理的引导整个团队如何正确的应对风险，制定有效的风险管理策略'
        },
         {
          title:'制定规范',
          content:'基本的产品原则和项目规范的产品成功的基础条件，且必须在正式的场合全体的一致理解'
        },
         {
          title:'明确计划',
          content:'合理、有效的拆分项目任务，制定清晰的计划与团队达成一致，确保计划被真正执行'
        }
      ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
    handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width:300px;
  margin: 0 auto;
  margin-top: 38px;
  margin-bottom: 44px;
  text-align: center
}
.el-main_second div {
  font-size: 35px;
  position: relative;
  font-weight: bold
}
.el-main_second div::after {
  content: '';
  width: 66px;
  height: 3px;
  background: #00A5E4;
  position: absolute;
  left: 120px;
  bottom: -10px;
}
.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 286px;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 286px;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px;
 
}
.el-main_tir .ring {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  color: #fff;
  position: absolute;
  left: 30px;
  display: flex;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 10px 10px 10px #333
}
.el-main_tir .ring1 {
   width: 160px;
  height: 160px;
  border-radius: 50%;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  color: #fff;
  position: absolute;
  left: 240px;
  top:-60px;
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  box-shadow: 10px 10px 10px #333
}
.el-main_tir .ring2 {
   width: 130px;
  height: 130px;
  border-radius: 50%;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  color: #fff;
  position: absolute;
  left: 240px;
  top:110px;
  display: flex;
  justify-items: center;
  align-items: center; 
  overflow: hidden;
  box-shadow: 10px 10px 10px #333
}
.el-main_tir .ring3 {
   width: 150px;
  height: 150px;
  border-radius: 50%;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  color: #fff;
  position: absolute;
  left:400px;
  top:10px;
  display: flex;
  justify-items: center;
  align-items: center; 
  overflow: hidden;
  box-shadow: 10px 10px 10px #333
}
.el-main_tir .ring4 {
   width: 150px;
  height: 150px;
  border-radius: 50%;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  color: #fff;
  position: absolute;
  left: 370px;
  top:170px;
  display: flex;
  justify-items: center;
  align-items: center; 
  overflow: hidden;
  box-shadow: 10px 10px 10px #333
}
.el-main_tir .ring span {
 
  font-size: 24px;

}
.el-main_tir .ring1 span {

  font-size: 16px
}
.el-main_tir .ring2 span {
  font-size: 16px
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   