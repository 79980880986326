<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="../assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
          <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='首页'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
    </el-header>
    <el-main class="el-main-first">
      <div style="margin-bottom:50px">
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div class="el-main_second">
               <el-divider>平台系统</el-divider>
         <div style="  text-align: center;font-size:16px;margin-top:50px"> 为各行业提供优质平台</div>
     
      </div>
     <div class="el-main-third">
       <div class="el-main-third-first">
          <div class="firstUp">
            <div class="item-box">
                <div class="inner-wap">
                  <span>
                      <i class="element-icons el-icondashuju" style="font-size:50px;"></i>
                       <p>大数据管理平台</p>
                  </span>
                  <a class="lis" @click='toNext(1)'>
                    了解更多
                  </a>
                </div>
            </div>
            
          
          </div>
       </div>
      <div class="el-main-third-first">
          <div class="firstUp">
            <div class="item-box">
                <div class="inner-wap">
                  <span>
                      <i class="element-icons el-iconwulianwang" style="font-size:50px;"></i>
                       <p>物联网管理平台</p>
                  </span>
                  <a class="lis" @click='toNext(2)'>
                    了解更多
                  </a>
                </div>
            </div>
            
          
          </div>
       </div>
     <div class="el-main-third-first">
          <div class="firstUp">
            <div class="item-box">
                <div class="inner-wap">
                  <span>
                      <i class="element-icons el-iconpingmu" style="font-size:50px;"></i>
                       <p>数据大屏可视化展示平台</p>
                  </span>
                  <a class="lis" @click='toNext(3)'>
                    了解更多
                  </a>
                </div>
            </div>
            
          
          </div>
       </div>
       <div class="el-main-third-first">
          <div class="firstUp">
            <div class="item-box">
                <div class="inner-wap">
                  <span>
                      <i class="element-icons el-iconduanxin1" style="font-size:50px;"></i>
                       <p>短信管理平台</p>
                  </span>
                  <a class="lis" @click='toNext(4)'>
                    了解更多
                  </a>
                </div>
            </div>
            
          
          </div>
       </div>
     </div>
      <div style="height:90px"></div>
	  <div>
		  
	  </div>
     <el-carousel height="560px">
        <el-carousel-item v-for="item in imageList" :key="item.index">
          <div class="el-main-four">
            <div class="el-main-four-one" style="width: 100%;text-align: center;">
				<div style="font-size: 30px;color: #fff;padding: 30px 0 100px 0;">产品及解决方案</div>
            </div>
            <div class="el-main-four-two">
              <div class="el-main-four-two-item" @click='gocp(item.url1)'>
                <div style="text-align:center">
                  <img :src="item.imgone" alt  style="width:100px;height:100px"/>
                </div>
                <div class="el-main-four-two-item-font">
                  <div>{{item.title}}</div>
                  <div>{{item.text}}</div>
                </div>
              </div>
              <div class="el-main-four-two-item" @click='gocp(item.url2)'>
                <div>
                  <img :src="item.imgsecond" alt style="width:100px;height:100px"/>
                </div>

                <div class="el-main-four-two-item-font">
                  <div>{{item.titlesecond}}</div>
                  <div>{{item.textsecond}}</div>
                </div>
              </div>
              <div class="el-main-four-two-item" @click='gocp(item.url3)'>
                <div>
                  <img :src="item.imgthird" alt style="width:100px;height:100px" v-show='item.imgthird!=""'/>
                </div>

                <div class="el-main-four-two-item-font">
                  <div>{{item.titlethird}}</div>
                  <div>{{item.textthird}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="el-box" style="margin-bottom: 0;">
      <div class="el-main_second">
        <el-divider style='background: #fff;'>公司优势</el-divider>
         <div style="  text-align: center;font-size:16px;margin-top:50px"> 专业的物联网大数据解决方案服务商</div>
      </div>
      <div class="el-main-third-six">
           <div class="el-main-third-six-advantage" v-for="(item,index) in advantageList" :key="index">
             <div class="el-main-third-six-box">
                 <div class="el-main-third-six-box-title">{{item.title}}</div>
                 <div class="el-main-third-six-box-content">
                    <!-- <img src="" alt=""> -->
                    <div style="  display: flex;align-items: center" v-show="item.text!=''">
                      <img src="@/assets/fj.png" alt="" style="width:20px;height:20px;margin-right:5px">
                      <p v-html="item.text"></p>
                    </div>
                     <div style="  display: flex;align-items: center" v-show="item.text1!=''">
                      <img src="@/assets/fj.png" alt="" style="width:20px;height:20px;margin-right:5px">
                      <p v-html="item.text1"></p>
                    </div>
                     <div style="  display: flex;align-items: center" v-show="item.text2!=''">
                      <img src="@/assets/fj.png" alt="" style="width:20px;height:20px;margin-right:5px">
                      <p v-html="item.text2"></p>
                    </div>
                 </div>
             </div>
           </div>
         </div>
         </div>
    
     
       <!-- <div class="el-main_second" style="width:800px">
        <el-divider>我的客户及合作伙伴</el-divider>
        <div style="  text-align: center;font-size:16px;margin-top:50px">合作伙伴共建专业稳定的物联网大数据服务</div>
      </div>
      <div class="el-main-first-five">
        <div class="el-main-first-five-item">
         <div>
            <img src="../assets/bgy1.png" alt />
          </div>
         <div>
            <img src="../assets/yd1.png" alt />
          </div>
          <div>
            <img src="../assets/sy1.png" alt />
          </div>
          <div>
            <img src="../assets/jn1.png" alt />
          </div>
          <div>
            <img src="../assets/dx1.png" alt />
          </div>
          <div>
            <img src="../assets/gs1.png" alt />
          </div>
        </div>
        <div class="el-main-first-five-item">
          <div>
            <img src="../assets/yd1.png" alt />
          </div>
          <div>
            <img src="../assets/dx1.png" alt />
          </div>
          <div>
            <img src="../assets/bgy1.png" alt />
          </div>
          <div>
            <img src="../assets/gs1.png" alt />
          </div>
          <div>
            <img src="../assets/jn1.png" alt />
          </div>
          <div>
            <img src="../assets/sy1.png" alt />
          </div>
        </div>
        <div class="el-main-first-five-item" style="margin-bottom:136px">
          <div>
            <img src="../assets/sy1.png" alt />
          </div>
          <div>
            <img src="../assets/gs1.png" alt />
          </div>
          <div>
            <img src="../assets/dx1.png" alt />
          </div>
          <div>
            <img src="../assets/yd1.png" alt />
          </div>
          <div>
            <img src="../assets/jn1.png" alt />
          </div>
          <div>
            <img src="../assets/bgy1.png" alt />
          </div>
        </div>
      </div> -->
    </el-main>
    <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
        
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
</template> 

<script >

export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      routerpath: "",
      routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
      platformList:[
           {
              name:'大数据管理平台',
              url:''
            },
             {
              name:'物联网管理平台',
              url:''
            },
             {
              name:'数据大屏可视化展示平台',
              url:''
            },
             {
              name:'短信管理平台',
              url:''
            }
      ],
      imageList: [
        {
          index: 1,
          title: "云广播",
          text:
            "城镇化高端发展理念、信息化监管完善、多终端覆盖、管理智能",
          imgone: require("@/assets/gb1s.png"),
		  url1:'/broadcast',
          titlesecond: "智慧园区",
          textsecond:
            "物联网、大数据移动互联、云计算",
          imgsecond: require("@/assets/yqs.png"),
		  url2:'/park',
          titlethird: "智慧充电桩",
          textthird:
            "智能化、可运营、“互联网+”的城市级安全充电解决方案",
          imgthird: require("@/assets/cdzs.png"),
		  url3:'/charging',
        },
        {
          index: 2,
          title: "云对讲机",
          text:
            "用于指挥调度、协同工作的半双工集群通信业务应用",
          imgone: require("@/assets/djjs.png"),
		  url1:'/walkie',
          titlesecond: "智能消防",
          textsecond:
            "感应迅速、维修方便、巡查全面、缩减时间",
          imgsecond: require("@/assets/xfs.png"),
		  url2:'/firecontrol',
           titlethird: "",
          textthird:
            "",
          imgthird: '',
		  url3:'',
        },
      ],
       advantageList:[
        {
          title:'公司资源',
          text:'优质的智慧城市渠道资源',
          text1:'中国移动、中国联通、中国电信三大运 营商的核心合作伙伴',
          text2:'多个网络平台覆盖至全国、全世界，达 到移动网络无缝运营'
        },
         {
          title:'公司团队',
          text:'超100人以上的研发及咨询团队',
          text1:'研发投入达销售额的15%以上',
          text2:'特聘行业专家、顾问15余名，主持或参与多个行 业标准制定，专注行业信息化应用及物联网产品研究'
        },
         {
          title:'公司技术能力',
          text:'在物联网平台、大数据、智能硬件嵌入 式开发等领域具有丰富的产品研发及落 地解决方案',
          text1:'',
          text2:''
        },
         {
          title:'公司渠道服务能力',
          text:'具有物联网产品落地推广能力，网络覆 盖全国',
          text1:'',
          text2:''
        }
      ]
    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
	gocp(i){
		 this.$router.push({path:i})
	},
      getStringColorReplace:function() {
                //假设使用固定的文本，可后台接收
                var show_text = this.advantageList[1].text;
                var replace_text= "超100人以上"
                var color_dict = "rgb(76,142,218)"
                var font_sizes = '22px'
                var font_wei = '700'
                var replaceString = '<span style="color: ' + color_dict + ';font-size:'+font_sizes+';font-weight:'+font_wei+'">' + replace_text + "</span>";
                    show_text = show_text.replace(RegExp(replace_text,'g') ,replaceString);
                this.advantageList[1].text = show_text
                // 
                 var show_text1 = this.advantageList[1].text1;
                var replace_text1= "15%以上"
                var color_dict1 = "rgb(76,142,218)"
                var font_sizes1 = '22px'
                var font_wei1 = '700'
                var replaceString1 = '<span style="color: ' + color_dict1 + ';font-size:'+font_sizes1+';font-weight:'+font_wei1+'">' + replace_text1 + "</span>";
                    show_text1 = show_text1.replace(RegExp(replace_text1,'g') ,replaceString1);
                this.advantageList[1].text1 = show_text1
                // 
                  var show_text2 = this.advantageList[1].text2;
                var replace_text2= "15余名"
                var color_dict2 = "rgb(76,142,218)"
                var font_sizes2 = '22px'
                var font_wei2 = '700'
                var replaceString2 = '<span style="color: ' + color_dict2 + ';font-size:'+font_sizes2+';font-weight:'+font_wei2+'">' + replace_text2 + "</span>";
                    show_text2 = show_text2.replace(RegExp(replace_text2,'g') ,replaceString2);
                this.advantageList[1].text2 = show_text2

            },
             handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            },
           toNext(i){
             if(i==1){
               this.$router.push({path:'/bigdata'})
             }else if(i==2){
                this.$router.push({path:'/things'})
             }else if(i==3){
                this.$router.push({path:'/largescreen'})
             }else if(i==4){
                this.$router.push({path:'/message'})
             }
           }
  },
  mounted() {
    console.log(this.$route.path);
    this.getStringColorReplace()
    this.routerpath = this.$route.path;
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #f2f6fc;
}
.el-carousel__button {
  width: 53px;
  height: 3px;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main_second {
  width: 500px;
  margin: 0 auto;
  margin-bottom: 50px
}

.el-main-third {
  display: flex;
  justify-content: space-between;
  width: 1140px;
  margin: 0 auto;
}

.el-main-third::after {
  display: table;
  content: "";
}
.el-main-third-one-font {
  margin: 0 auto;
  margin-top: -134px;
}
.el-main-third-one-font div:nth-child(1) {
  color: #313131ff;
  font-size: 21px;
  font-weight: 400;
  text-align: center;
}

.el-main-third-one-font div:nth-child(2) {
  color: #313131ff;
  font-size: 16px;
  font-weight: 400;
  width: 224px;
  margin: 0 auto;
}
.el-main-four {
  display: block;
  /* background-image: "@/assets/产品服务4.png"; */
  width: 100%;
  height: 560px;
  background-image: url("../assets/jjbg.png");
  /* margin-top: 65px; */
}
.el-main-four-one {
  width: 145px;
  margin: 0 auto;
  /* margin-bottom: 79px; */
}
.el-main-four-one img {
  padding-top: 41px;
  padding-bottom: 79px;
}

.el-main-four-two {
  display: flex;
  justify-content: space-between;
  width: 1026px;
  margin: 0 auto;
  text-align: center;
}
.el-main-four-two-item {
  width: 266px;
  display: flex;
  flex-direction: column;
}

.el-main-four-two-item-font div:nth-child(1) {
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 17px;
  padding-top: 26px;
}
.el-main-four-two-item-font div:nth-child(2) {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.el-main-five {
  /* margin-top: 68px; */
  margin: 0 auto;
  width: 145px;
  /* margin-bottom: 101px; */
  height: 38px;
  padding-top: 68px;
  padding-bottom: 101px;
}
.el-main-five div {
  font-size: 35px;
  position: relative;
  font-weight: bold
}
.el-main-five div::after {
  content: '';
  width: 66px;
  height: 1px;
  background: #9D9D9D;
  position: absolute;
  left: 40px;
  bottom: -10px
}

.el-main-six-item-one {

  width: 531px;
  height: 261px;
  margin-right: 64px;
  margin-bottom: 53px;
}
.el-main-six-item-one div {
  padding-top: 199px;
  width: 473px;
  color: rgba(49, 49, 49, 1);
  font-size: 18px;
  margin: 0 auto;
  font-weight: 400;
}
.el-main-six-item-two {

  width: 531px;
  height: 261px;
}
.el-main-six-item-two div {
  padding-top: 199px;
  width: 473px;
  color: rgba(49, 49, 49, 1);
  font-size: 18px;
  margin: 0 auto;
  font-weight: 400;
}
.el-main-six-item-three {

  width: 531px;
  height: 261px;
  margin-right: 64px;
}
.el-main-six-item-three div {
  padding-top: 199px;
  width: 473px;
  color: rgba(49, 49, 49, 1);
  font-size: 18px;
  margin: 0 auto;
  font-weight: 400;
}
.el-main-six-item-four {
 
  width: 531px;
  height: 261px;
}
.el-main-six-item-four div {
/*  padding-top: 199px; */
  width: 473px;
  color: rgba(49, 49, 49, 1);
  font-size: 18px;
  margin: 0 auto;
  font-weight: 400;
}
.el-main-six-item {
  width: 1116px;
  margin: 0 auto;
  display: flex;
}
.el-main-six-item-second {
  width: 1116px;
  margin: 0 auto;
  display: flex;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
}
/* .el-divider {
  background: #252B3A
} */

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}

.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main-second-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
    
  } 
  .el-main-second-box div {
    padding: 15px 30px;
    color: #fff;
    border-radius: 5px
  }
   .el-main-second-box div:nth-of-type(odd){
     background: #1F8E8F
   }
      .el-main-second-box div:nth-of-type(even){
     background: #1A6D8C
   }
   .margin-botm {
     margin-bottom: 10px;
   }
.el-main-third-six {
 width: 80%;
display: flex;
flex-wrap: wrap;
margin: 0 auto
}
.el-main-third-six-advantage {
flex: 1;
width: 50%;
min-width: 50%;
max-width: 50%;
margin-bottom: 20px;
min-height: 230px
}
.el-main-third-six-box {
  width: 80%;
  margin: 0 auto;
}
.el-main-third-six-box-title {
  text-align: center;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  padding: 5px 0;
  color: #fff;
  font-size: 22px
}
.el-main-third-six-box-content {
  margin: 20px;
  font-size: 18px;
  padding: 0 20px
}
.el-main-third-six-box-content p {
  margin: 10px 0
}
.el-divider__text{
  font-size: 35px;
  background: #fff;
}
.el-main-first {
  background: #fff
}
.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 20px
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}
.el-main-first-five {
  padding-top: 50px
}
.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-box {
  background:#131C73;
  padding:30px 0;
  color:#fff;
  margin-bottom: 50px
}
.el-box .el-divider__text{
  font-size: 35px;
  background: #131C73;
  color: #fff
}
.el-main-third-first {
  background: #F6F8FA;
}
.el-main-third-first {
   overflow: hidden;
}
.el-main-third-first  .item-box {
   padding: 50px;
    background: #F6F8FA;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    padding-bottom: 20px
   
}
.inner-wap {
  transition:0.4s
}
.lis {
    display: block;
    width: 90px;
    line-height: 32px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin: 22px auto 0;
    text-align: center;
    color: #fff;
    font-size: 15px;
    opacity: 0;
    cursor: pointer
}
.inner-wap span i {
  color: #375EEE
}
.inner-wap span{
      display: block;
    width: 165px;
    height:80px;
    margin: 0 auto 26px;
    position: relative;
    font-size: 15px;
}
.item-box:hover {
  background: #375EEE;
    transition: 0.4s;
}
.item-box:hover .inner-wap {
      transition: 0.4s;
    transform: translateY(-30px);
    color: #fff
}
.item-box:hover .inner-wap i {
  color: #fff
}
.item-box:hover .lis {
  opacity: 1;
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   