<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='解决方案'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
               <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <el-divider>云广播</el-divider>
       <div style="  text-align: center;font-size:16px;margin-top:50px"> 
           传达中央政府和地方政府的防控精神和防控工作
</div>
      </div>
       
        <div class="bigImg" style="margin-bottom:40px">
            <div style="text-indent:2em">
                为了配合政府的疫情防控宣传工作，需要针对应急事件和疫情防控进行及时的宣传广播，
                配合政府共同打好疫情防控攻坚战，<span style="color:#1A6D8C;font-weight:700">催生建设4G村村通平台</span>，
                实现
                <span style="color:#1A6D8C;font-weight:700">
                    能及时把疫情重要信息、
                疫情防控工作和防疫知识通过应急广播的形式通知到全省各个行政村的村民，
                传达中央政府和地方政府的防控精神和防控工作。
                </span>
            </div>
        </div>
        <div class="bigImg">
            <div class="flex">
                <div class="flexText" style="text-align:right">
                    <div><i class="el-icon-info"></i>集约、智能</div>
                    <div><i class="el-icon-info"></i>绿色、低碳</div>
                </div>
                <div class="square">
                    <div>
                        <img src="@/assets/yun.png" alt="">
                    </div>
                    <div style="width:100px;color:#fff;font-size:14px;text-align:center">高端发展理念</div>
                </div>
            </div>
            <div class="flex">
                
                <div class="square">
                    <div>
                        <img src="@/assets/yun1.png" alt="">
                    </div>
                    <div style="width:100px;color:#fff;font-size:14px;text-align:center">监管完善</div>
                </div>
                <div class="flexText">
                    <div><i class="el-icon-info"></i>设备集中管理与调度</div>
                    <div><i class="el-icon-info"></i>人员权限集中管控</div>
                </div>
            </div>
        </div>
         <div class="bigImg" style="margin-bottom:50px">
            <div class="flex">
                <div class="flexText" style="text-align:right">
                    <div><i class="el-icon-info"></i>村村通后台管理系统</div>
                    <div><i class="el-icon-info"></i>接口服务</div>
                    <div><i class="el-icon-info"></i>APP端</div>
                </div>
                <div class="square">
                    <div>
                        <img src="@/assets/yun2.png" alt="">
                    </div>
                    <div style="width:100px;color:#fff;font-size:14px;text-align:center">多终端覆盖</div>
                </div>
            </div>
            <div class="flex">
                
                <div class="square">
                    <div>
                        <img src="@/assets/yun3.png" alt="">
                    </div>
                    <div style="width:100px;color:#fff;font-size:14px;text-align:center">监管完善</div>
                </div>
                <div class="flexText">
                    <div><i class="el-icon-info"></i>智能化、信息化</div>
                    <div><i class="el-icon-info"></i>提升效率，推进管理、降低成本</div>
                    <div>
                        <i class="el-icon-info"></i>提升竞争力
                    </div>
                </div>
            </div>
        </div>
            <div class="bigImg" style="margin-bottom:80px">
           <img src="@/assets/yun4.png" alt="" style="width:70%">
           <div>
               <span style="color:#1A6D8C;font-weight:700"> 4G 智慧云广播</span>
              是由服务器 、广播是由服务器 、软件、 移动端软件、  移动端IP 话筒、收扩机 话筒、收扩机 +号角 /一体式终端构成，系统是建立于一体式终端构成，是建立于网络架构上的广播系统，与数字网络架构上的广播系统，与数字架构相似， 同样是网络编、解码的过程，可同时采用 有线 /无线 广播的方式。每个网络广播适配器无需独立的无需独立的 IP 地址，只需接入 3G/4G/5G 网络就实现广播的各项功能。

           </div>
        </div>
           <div class="bigImg" style="margin-bottom:80px">
           <img src="@/assets/yun5.png" alt="" style="width:90%">
           
        </div>
          <div class="bigImg" >
          
           <div class="textBox">
               <div style="color:#1A6D8C;font-weight:700;margin-bottom:10px"> 方案优势</div>
                <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 支持语音、文本两种通知模式
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 提供设备管理，进入GIS地图界面显示终端位置

                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 支持自定义区域分组通知模式
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 支持分级管理，权限越高控制区域越多

                </div>

           </div>
            <img src="@/assets/yun8.png" alt="" style="width:70%;margin-left:10px">
        </div>
         <div class="bigImg" >
          
           <div class="textBox">
               <div style="color:#1A6D8C;font-weight:700;margin-bottom:10px"> 应用场景</div>
                <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 村村通市区/镇/乡/村广播
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 高速公路广播
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 大型园区
                </div>
           </div>
            <img src="@/assets/yun9.png" alt="" style="width:70%;margin-left:10px">
        </div>
    </el-main>
 <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
        
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
          footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
     routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
    things:[
        {
            title:'云端监控',
            text:'通过设备列表、监控地图等，对设备进行全方位的管理',
            pic:'t1'
        },
           {
            title:'智能管理',
            text:'平台部署后，全部设备都可通过独创AMS智能运维技术实现自动监控和管理，实现企业物联网自动化管理。',
             pic:'t2'
        },   {
            title:'数据分析',
            text:'流量、费用、子账户数据、设备状态、定位等全方位多维度数据分析和展示。',
             pic:'t3'
        },   {
            title:'高效传输',
            text:'支持三大运营商的NB-IOT高效传输协议。',
             pic:'t4'
        },   {
            title:'安全稳定',
            text:'三大运营商移动网络支持，专业服务保障',
             pic:'t5'
        }
    ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
     handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 40px
}
.el-divider__text{
  font-size: 35px;
  background: #fff;
}

.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px
}
.bigImg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px
}
.square {
    width: 150px;
    height: 150px;
    background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
    border-radius: 25px;
    margin-left:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.square img{
    width: 100px;
    height: 100px;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px
}
.flexText {
    padding: 0 20px
}
.flexText div {
    margin: 10px;
    width: 150px;
}
.textBox div {
    margin: 10px
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   