<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='解决方案'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
                <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <el-divider>云对讲机</el-divider>
      </div>
      <div class="bigImg">
          <div>
              <span style="color:#1A6D8C;font-weight:700">云对讲业务</span>
              基于
              <span style="color:#1A6D8C;font-weight:700">移动公网广覆盖、大容量的移动通信网络和专业星级的业务管理平台，利用 Push to Talk（PTT，一键通）技术，</span>
             
              通过带有云对讲功能的专业手机终端，为客户提供移动通信网络覆盖范围内的半双工集群通信业务。通过公网通信网络为客户提供的
                <span style="color:#1A6D8C;font-weight:700">用于指挥调度、协同工作的半双工集群通信业务应用。</span>
              采用共网集群的业务模式，同时融合了手机与对讲机的功能。

          </div>
      </div>
        <div class="bigImg" style="margin-bottom:80px">
            
            <div style="width:50%" class="flexLeft">
               <div>
                     <img src="@/assets/w1.png" alt="">
                     <div style="text-align:center;font-weight:700;color:#1F8E8F">
                         T7 4V
                     </div>
               </div>
                   <div class="floats">
                       <div>
                           <span>安卓5.1系统</span>
                       </div>
                        <div>
                           <span>录音/录像</span>
                       </div>
                        <div>
                           <span>Wifi、GPS、蓝牙、NFC选配</span>
                       </div>
                        <div>
                           <span>TF卡扩展</span>
                       </div>
                        <div>
                           <span>高像素摄像头</span>
                       </div>
                        <div>
                           <span>电话/短信</span>
                       </div>
                        <div>
                           <span>用户集群对讲</span>
                       </div>
                        <div>
                           <span>超大音量喇叭</span>
                       </div>
                   </div>
            </div>
           <div style="width:50%" class="flexLeft">
                 <div>
                     <img src="@/assets/w1.png" alt="">
                     <div style="text-align:center;font-weight:700;color:#1F8E8F">
                         GP-398
                     </div>
               </div>
                    <div class="floats">
                       <div>
                           <span>4G全国对讲</span>
                       </div>
                        <div>
                           <span>北斗/GPS定位</span>
                       </div>
                        <div>
                           <span>多种网络制式</span>
                       </div>
                        <div>
                           <span>大容量电池</span>
                       </div>
                        <div>
                           <span>自由选呼</span>
                       </div>
                        <div>
                           <span>USB充电</span>
                       </div>
                        <div>
                           <span>高质音效</span>
                       </div>
                        <div>
                           <span>空中写码/升级</span>
                       </div>
                   </div>
            </div>
        </div>
          
          <div style="width:100%;margin-bottom:80px">
               <div class="el-main-third-six" >
           <div class="el-main-third-six-advantage" v-for="(item,index) in advantageList" :key="index">
             <div class="el-main-third-six-box">
                 <div class="el-main-third-six-box-title">{{item.title}}</div>
                 <div class="el-main-third-six-box-content">
                     <div v-show='item.list.length>0'>
                        <div v-for="(items,indexs) in item.list" :key='indexs' style="margin:15px 0">
                            <div class='fles' >
                                <div style="width:30%">
                                    {{items.name}}
                                </div>
                                <div  style="width:70%">
                                    <div v-for="(items1,indexs1) in items.textList" :key="indexs1" style="margin:10px 0" >
                                         <i class="el-icon-info"></i> {{items1}}
                                    </div>
                                  
                                </div>
                            </div>

                        </div>
                     </div>
                     <div v-show='item.list1.length>0'>
                           <div v-for="(items2,indexs2) in item.list1" :key="indexs2" style="margin:10px 0" >
                                         <i class="el-icon-info"></i> {{items2}}
                            </div>
                     </div>
                 </div>
             </div>
           </div>
         </div>
          </div>
        
        
         <div class="bigImg" >
          
           <div class="textBox">
               <div style="color:#1A6D8C;font-weight:700;margin-bottom:10px"> APP管理配置</div>
                <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> App可以与对讲机实现对讲
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> App与App间可机互对讲

                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 添加好友
                </div>
                  <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 设置群组

                </div>
                <div >
                   <i class="el-icon-check" style="margin-right:10px"></i> 对话记录，可以回听消息，不错过任务信息

                </div>

           </div>
            <img src="@/assets/w5.png" alt="" style="width:300px;margin-left:100px">
        </div>
      
         
      
      
         
          
    </el-main>
    <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
       footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
         routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
         advantageList:[
        {
          title:'硬件关键特性',
          list:[
             {
                 name:'T7 4V',
                 textList:[
                     '高通MSM8909 4核1.1GHz',
                     '内存8GB ( EMMC) +1GB ( DDR2 )',
                     'Android 5.1操作系统',
                     '最大上行速度: 50Mbps;最大下行速度: 150Mbps',
                     'WIFI+蓝牙、GPS、NFC（选配）',
                     '2.4英寸TFT触摸屏',
                     '支持TF内存卡(最大64G)',
                     '前置摄像头200W,后置摄像头800W',
                     '电池容量4200mAh'
                 ]
             },
              {
                 name:'GP-398',
                 textList:[
                     '高通9X07',
                     '占用宽带TD_LTE：≤20MHz',
                     '屏幕像素128*64',
                     '外置天线',
                     '1.77彩屏',
                     '电池容量4000mAh'
                 ]
             }
         ],
         list1:[]
        },
         {
          title:'设备端核心功能',
         list:[],
         list1:[
             '网络对讲',
             '快速组队',
             '语音回放',
             '实时视频',
             '临时对讲呼叫',
             'GPS定位',
             '一键应急求救',
             '智能语音助手',
             '固件在线升级',
             '蓝牙音箱模式'
         ]
        },
         {
          title:'APP端核心功能',
         list:[
                {
                 name:'设备管理',
                 textList:[
                     '设备绑定',
                     '设备共享',
                     '设备网络配置',
                     '设备定位查看',
                     '求救信息查看'
                 ]
             },
              {
                 name:'联系',
                 textList:[
                     '网络对讲',
                     '视频回放',
                     '对讲记录',
                     '通讯录管理'
                 ]
             }
         ],
         list1:[]
        },
      ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
     handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 40px
}
.el-divider__text{
  font-size: 35px;
  background: #fff;
}

.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px
}
.bigImg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 50px
}
.square {
    width: 150px;
    height: 150px;
    background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
    border-radius: 25px;
    margin-left:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}
.square img{
    width: 100px;
    height: 100px;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px
}
.flexText {
    padding: 0 20px
}
.flexText div {
    margin: 10px;
    width: 150px;
}
.textBox div {
    margin: 10px
}
.flexLeft {
    display: flex;
    align-items: center
}
.floats div {
    width:40%;
    float: left;
    text-align: center;
    border: 2px solid #76BABB;
    color: #76BABB;
    margin: 5px;
    padding: 10px;
    border-radius: 10px
}
.el-main-third-six {
 width: 80%;
display: flex;
flex-wrap: wrap;
margin: 0 auto;
padding: 10px;
padding-top: 30px
}
.el-main-third-six-advantage {
flex: 1;
width: 30%;
min-width: 30%;
max-width: 30%;
margin-bottom: 20px;
min-height: 230px
}
.el-main-third-six-advantage:first-child {
    width: 40%;
min-width: 40%;
max-width: 40%;
}
.el-main-third-six-box {
  width: 80%;
  margin: 0 auto;
}
.el-main-third-six-box-title {
  text-align: center;
  background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  padding: 5px 0;
  color: #fff;
  font-size: 22px
}
.el-main-third-six-box-content {
  margin: 20px;
  font-size: 18px;
  padding: 0 20px
}
.el-main-third-six-box-content p {
  margin: 10px 0
}
.fles {
    display: flex;
    align-items: center;
    justify-content: flex-start
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   