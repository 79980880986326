<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
        </div>
      </div>
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div class="el-main_second">
        <div class="el-main-second-first" style="margin-top:121px">
          <div class="el-main-second-first-left" style="margin-right:36px">
            <div style>
              <img src="@/assets/产品页/智能充电桩.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
          <div class="el-main-second-first-right">
            <img src="@/assets/产品页/智能充电桩1.png" alt />
          </div>
        </div>

        <div class="el-main-second-first" style="float:left">
          <div class="el-main-second-first-right" style="margin-right:36px">
            <img src="@/assets/产品页/智能门锁1.png" alt />
          </div>
          <div class="el-main-second-first-left">
            <div class="el-main-second-first-left-img">
              <img src="@/assets/产品页/智能门锁.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
        </div>

        <div class="el-main-second-first" style="margin-top:121px">
          <div class="el-main-second-first-left" style="margin-right:36px">
            <div style>
              <img src="@/assets/产品页/智能烟感.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
          <div class="el-main-second-first-right">
            <img src="@/assets/产品页/智能烟感1.png" alt />
          </div>
        </div>

        <div class="el-main-second-first" style="float:left">
          <div class="el-main-second-first-right" style="margin-right:36px">
            <img src="@/assets/产品页/短信服务1.png" alt />
          </div>
          <div class="el-main-second-first-left">
            <div class="el-main-second-first-left-img">
              <img src="@/assets/产品页/短信服务.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
        </div>

        <div class="el-main-second-first" style="margin-top:121px">
          <div class="el-main-second-first-left" style="margin-right:36px">
            <div style>
              <img src="@/assets/产品页/智能消杀灯.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
          <div class="el-main-second-first-right">
            <img src="@/assets/产品页/智能消杀灯1.png" alt />
          </div>
        </div>

        <div class="el-main-second-first" style="float:left">
          <div class="el-main-second-first-right" style="margin-right:36px">
            <img src="@/assets/产品页/定制开发1.png" alt />
          </div>
          <div class="el-main-second-first-left">
            <div class="el-main-second-first-left-img">
              <img src="@/assets/产品页/定制化开发.png" alt />
            </div>
            <div style>{{msg}}</div>
            <div style="height:40px;display:block"></div>
          </div>
        </div>
      </div>
    </el-main>
   <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
       footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      routerpath: "",
      routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
    };
  },
  methods: {
    open: function () {
      this.$router.push("/projectserves");
    },
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #f2f6fc;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main_second {
  /* width: 145px;
  margin: 0 auto;
  margin-top: 38px;
  margin-bottom: 44px; */
}
.el-main-second-first {
  width: 1162px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  margin-bottom: 153px;
  justify-content: space-between;
}
.el-main-second-first-left {
  display: flex;
  flex-direction: column;
  width: 647px;
  text-align: left;
}
.el-main-second-first-left div:nth-child(1) {
  /* display: block;
  width: 288px;
  height: 88px; */
}

.el-main-second-first-left div:nth-child(2) {
  width: 647px;
  height: 233px;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: rgba(49, 49, 49, 1);
  margin-top: 33px;
}

.el-main-second-first-left-img {
  width: 647px;
  text-align: right;
}

.el-main-second-first-right {
  width: 865px;
  /* height: 320px; */
  /* background: #000; */
}

.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   