<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <!-- <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link> -->
            <el-dropdown   v-for="(item,index) in routerList"  :key="index">
          <span class="el-dropdown-link" @click="routerLink(item.router)" :class="{'el-color':item.name=='平台系统'}">
            {{item.name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items,indexs) in item.list" :key="indexs">
              <div  @click="handleCommand(items)">
                {{items.name}}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
       <div class="el-main_second">
        <el-divider>短信平台</el-divider>
       <div style="  text-align: center;font-size:16px;margin-top:50px"> 
               短彩信应用平台是广州谐和信息科技发展有限公司为企事业单位定制开发的消息发布平台。它基于互联网和移动通信网，帮助企业方便、快捷的向目标用户发布文字、图片及语音信息。帮助企业降低沟通成本，提升沟通效率。适用于互联网注册会员、电商平台、快递通知、品牌会员服务（比如满意度，积分兑换、产品推广）、软件类（如ERP软件，会员管理软件，美发行业）、政府事业单位（比如自来水厂）等各行业
          
       </div>
       
      </div>
       
        <div class="bigImg">
            <div>
                <img src="@/assets/m.png" alt="">
            </div>
        </div>
      <div class="bigImg1">
           <div>
               <div style="font-weight:700;font-size:18px;margin-bottom:10px">
                    一种新的通信产品形态
               </div>
                <div style=" text-indent: 2em">
                    通信能力API中间件，将语音等通信基础API以及录音、TTS、IVR等通信相关的增值API。客户将通信类API无缝集成到应用中，为其终端用户提供随时随地的落地通信服务。

                </div>
            </div>
      </div>
     <div class="bigImg1">
           <div>
               <div style="font-weight:700;font-size:18px;margin-bottom:10px">
                    为客户创造价值
               </div>
                <div style=" text-indent: 2em">
                    更易开发：呼叫中心、语音、短信等通信能力

                </div>
                 <div style=" text-indent: 2em">
                    更易管理：权限、账号管理等

                </div>
            </div>
      </div>
      <div class="el-main_second">
        <el-divider>平台优势</el-divider>
      </div>
       <div class="bigImg2">
           <div class="imgHover" v-for="(item ,index) in things" :key="index">
               <div class="imgBg">
                   <img :src="require('@/assets/'+item.pic+'.png')" alt="">
               </div>
               <div style="margin-top:30px;font-weight:700;margin-bottom:10px">
                   {{item.title}}
               </div>
               <div style="width:50%;text-align:center">
                   {{item.text}}
               </div>
           </div>
        </div>
          <div class="el-main_second">
        <el-divider>资源优势</el-divider>
      </div>
      <div class="bigImg3">
          <div>
              <i class="el-icon-check"></i>
              拥有多条1069的通道资源，直连移动、联通、电信网关，保障可享有独立的端口，支持端口号自行扩展。
          </div>
          <div>
                <i class="el-icon-check"></i>
              运维服务保障毫秒响应，可承载大容量并发
          </div>
          <div>
                <i class="el-icon-check"></i>
              短信签名/模板快速审批，发送状态监控。
          </div>
          <div>
                <i class="el-icon-check"></i>
              支持黑名单通道，关键字审核，避免下发非法短信，影响企业信誉。
          </div>
          <div>
                <i class="el-icon-check"></i>
              支持各种协议接入，如http/cmpp/smpp等
          </div>
        
      </div>
      <div class="bigImg">
          <img src="@/assets/m1.png" alt="">
      </div>
    </el-main>
  <el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
         footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
     routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
    things:[
        {
            title:'极速体验',
            text:'验证码极速到达',
            pic:'t1'
        },
           {
            title:'三网合一',
            text:'移动联通电信一个都不能少，支持双向收发。',
             pic:'t2'
        },   {
            title:'网关到达率',
            text:'100%的网关到达率',
             pic:'t3'
        },   {
            title:'SDK接口',
            text:'支持JAVA/PHP/NET/C#等主流语言接口',
             pic:'t4'
        },   {
            title:'智能切换',
            text:'多条备份通道，智能切换，保证业务不中断',
             pic:'t5'
        },
         {
            title:'状态报告',
            text:'实时回送状态报告，让每条短信都经的起考验',
             pic:'t5'
        }
    ]

    };
  },
  methods: {
    routerLink(e){
      if(e !=''){
        this.$router.push({path:e})
      }
    },
     handleCommand(command) {
              if(command!=''){
                this.$router.push({path:command.url})
              }
            }
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 18px
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-color {
    color: #409EFF;
  }
  .el-main_second {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 40px
}
.el-divider__text{
  font-size: 35px;
  background: #fff;
}

.el-main_tir {
  width: 50%;
  margin: 0 auto;
  text-indent: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px
}
.el-main_tir div {
  width: 50%;
  height: 300px;
}
.el-main_tir1 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}
.el-main_tir1 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
}
.el-main_tir2 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
  display: flex;
  justify-content:center;
  align-items: center
}
.el-main_tir2 div {
  background: #0A6688;
  width: 30%;
  text-align: center;
  padding: 50px 10px;
  color: #fff;
  border-radius: 10px;
  margin: 10px
}
.el-main_tir3 {
  width: 50%;
  margin: 0 auto;
  font-size: 28px;
}
.el-main_tir4 {
  width: 50%;
  margin: 0 auto;
}
.el-timeline-item__timestamp {
  font-size: 20px
}
.bigImg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px
}
.bigImg1 {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px
}
.bigImg2 {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
   flex-wrap: wrap;
   padding-top: 20px
}
.imgBg {
    background: -webkit-linear-gradient(to right,#06C3B5,#0180C6); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(to right,#06C3B5,#0180C6); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(to right,#06C3B5,#0180C6); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right,#06C3B5,#0180C6); /* 标准的语法（必须放在最后） */
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
    }
    .imgBg img {
        width: 50px;
        height: 50px;
    }
    .imgHover {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 300px;
        flex: 1;
        width: 33%;
        min-width: 33%;
        max-width: 33%;
        margin-bottom: 30px
    }
    .bigImg3 {
         width: 60%;
    margin: 0 auto;
    margin-bottom:60px
    }
    .bigImg3 div {
        margin: 20px;
        text-indent: 2em
    }
    .el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   