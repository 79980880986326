import { render, staticRenderFns } from "./things.vue?vue&type=template&id=5d4a1937&scoped=true&"
import script from "./things.vue?vue&type=script&lang=js&"
export * from "./things.vue?vue&type=script&lang=js&"
import style0 from "./things.vue?vue&type=style&index=0&id=5d4a1937&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4a1937",
  null
  
)

export default component.exports