<template>
  <el-container class="container">
    <el-header style="padding:0px !important;">
      <div
        style="display:flex;margin:0 auto;width:900px;height:42px;margin-top:16px;margin-bottom:17px"
      >
        <img src="@/assets/logo.png" alt />
        <div class="header_font">
          <router-link
            :to="item.router"
            v-for="(item,index) in routerList"
            :key="index"
            v-bind:class="{ active: item.router==routerpath }"
          >{{item.name}}</router-link>
        </div>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>-->
    </el-header>
    <el-main class="el-main-first">
      <div>
        <img src="@/assets/banner.png" width="100%" alt />
      </div>
      <div class="el-main-first-second">
        <img src="@/assets/客户页/我们的客户.png" alt />
      </div>
      <div class="el-main-first-third">
        <div style="margin-top:0px">
          <img src="@/assets/客户页/地产.png" alt />
        </div>
        <div>
          <img src="@/assets/客户页/移动通信.png" alt />
        </div>
        <div>
          <img src="@/assets/客户页/运营商.png" alt />
        </div>
        <div>
          <img src="@/assets/客户页/工业制造.png" alt />
        </div>
        <div>
          <img src="@/assets/客户页/金融.png" alt />
        </div>
        <div>
          <img src="@/assets/客户页/其他.png" alt />
        </div>
      </div>
      <div class="el-main-first-four">
        <img src="@/assets/客户页/合作伙伴.png" alt />
      </div>
      <div class="el-main-first-five">
        <div class="el-main-first-five-item">
          <div>
            <img src="@/assets/客户页/碧桂园.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/移动.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/石油.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/节能.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/电信.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/工商.png" alt />
          </div>
        </div>
        <div class="el-main-first-five-item">
          <div>
            <img src="@/assets/客户页/移动.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/电信.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/碧桂园.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/工商.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/节能.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/石油.png" alt />
          </div>
        </div>
        <div class="el-main-first-five-item" style="margin-bottom:136px">
          <div>
            <img src="@/assets/客户页/石油.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/工商.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/电信.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/移动.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/节能.png" alt />
          </div>
          <div>
            <img src="@/assets/客户页/碧桂园.png" alt />
          </div>
        </div>
      </div>
    </el-main>
<el-footer height='360px'>
      <div class="footerBox">
          <div class="footLi" v-for="(item,index) in footList" :key='index'>
            <div style="margin-bottom:20px">{{item.name}}</div>
            <div class="liList" v-for="(items,indexs) in  item.list" :key="indexs" @click='handleCommand(items)'>
                {{items.name}}
            </div>
          </div> 
      </div>
      <!-- <div>
        <el-divider></el-divider>
        <div class="footerBz">
         版权所有 © 软件技术有限公司 2018 保留一切权利 苏ICP备xxxxxx号-5 苏B2-xxxxxxxxxx号
      </div>
      
      </div> -->
      
    </el-footer>
  </el-container>
  <!-- <div class="container">
  <div>{{msg}}</div>
  <div>
 <el-button>Click Me</el-button>
  </div>
  </div>-->
</template> 

<script >
export default {
  name: "index",
  data() {
    return {
        footList: [
        {
          name: "平台系统",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
             {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
           list:[
             {
                name:'落地服务',
                url:'/productService'
            }
           ]
        },
        {
          name: "关于我们",
           list:[
              {
                name:'公司简介',
                url:'/Companyprofile'
            },
             {
                name:'加入我们',
                url:'/joinus'
            },
             {
                name:'联系我们',
                url:'/callme'
            },
             {
                name:'公司资质',
                url:'/developmenthistory'
            }
           ]
        },
      ],
      msg:
        "针对电动自行车充电安全问题，中移物联网自主研发了一套智能化、可运营、“互联网+” 的城市级安全充电解决方案——和易充，集智能充电终端、移动物联专网、充电运营管理云平台、用户端app（iOS/安卓/微信端）。可广泛适用于共享充电、智慧社区、智慧城市、工厂园区等场景和领域，为广大电动车主提供更安全、更智能、更便捷的充电服务",
      msg1: "通过设备列表、监控地图等，对设备进行全方位的管理",
      msg2:
        "平台部署后，全部设备都可通过独创AMS智能运维技术实现自动监控和管理、企业物联网自动化管理",
      msg3:
        "流量、费用、子账户数据、设备状态、定位等全方位多维度数据分析和展示",
      msg4: "支持三大运营商的NB-IOT高效传输协议",
      msg5: "三大运营商移动网络支持，专业服务保障",
      routerpath: "",
      routerList: [
        {
          name: "首页",
          index: 1,
          router: "/",
           list:0
        },
        {
          name: "平台系统",
          index: 2,
          router: "",
          list:[
            {
              name:'大数据管理平台',
              url:'/bigdata'
            },
             {
              name:'物联网管理平台',
              url:'/things'
            },
             {
              name:'数据大屏可视化展示平台',
              url:'/largescreen'
            },
             {
              name:'短信管理平台',
              url:'/message'
            }
          ]
        },
        {
          name: "产品及解决方案",
          index: 3,
          router: "",
          list:[
            {
                name:'基础物联网服务',
                url:'/basics'
            },
            //   {
            //     name:'行业解决方案',
            //     url:''
            // },
              {
                name:'智能消防',
                url:'/firecontrol'
            },
            {
                name:'智能烟感',
                url:'/smoke'
            },
              {
                name:'云广播',
                url:'/broadcast'
            },
              {
                name:'智慧园区',
                url:'/park'
            },
              {
                name:'智慧充电桩',
                url:'/charging'
            },
              {
                name:'云对讲机',
                url:'/walkie'
            }
          ]
        },
        {
          name: "落地服务",
          index: 4,
          router: "/productService",
           list:0
        },
        // {
        //   name: "客户与我们",
        //   index: 4,
        //   router: "/customerandfriend",
        //    list:0
        // },
        {
          name: "关于我们",
          index: 5,
          router: "/Companyprofile",
           list:0
        },
      ],
    };
  },
  methods: {
    select(event) {
      var el = event.currentTarget;
      console.log(el);
    },
    // open: function () {
    //   this.$router.push("/projectserves");
    // },
  },
  mounted() {
    console.log(this.$route.path);
    this.routerpath = this.$route.path;
     window.scrollTo(0,500);
  },
};
</script>

<style scoped >
.container {
  max-width: 1920px !important;
  width: 100% !important;
  margin: 0 auto;
  background-color: #fff;
}
a {
  color: #000;
  text-decoration: none;
}
.active {
  color: #00a5e3ff;
}
/* .router-link-active{
color:#00a5e3ff;
} */
.header_font {
  font-size: 18px;
  color: #000;
  display: flex;
  width: 549px;
  justify-content: space-around;
  margin-left: 160px;
  align-content: center;
  height: 42px;
  line-height: 42px;
}
.header_font div:hover {
  color: #00a5e3ff;
}
.el-header {
  /* height: 75px; */
}
.el-header::after {
  display: table;
  content: "";
}
.el-main {
  padding: 0px;
  margin-top: 38px;
  overflow: hidden;
}

.el-main-first-second {
  width: 145px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 52px;
}

.el-main-first-third {
  /* display: flex; */
  margin: 0 auto;
  width: 940.2px;
  text-align: center;
  /* margin-top: -12px; */
}
.el-main-first-third div{
  margin-top: -12px;
}

.el-main-first-four {
  padding-top: 75px;
  padding-bottom: 75px;
  margin: 0 auto;
  width: 118px;
}

.el-main-first-five-item {
  width: 1041.4px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.el-footer {
  background: #3D3E49;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 100px
}
.el-divider {
  background: #252B3A
}

.footerBox {
  display: flex;
  width: 60%;
  margin: 0 auto;
  padding-top: 50px 
}
.footerBz {
  width: 60%;
  margin: 0 auto;
  color: #C5C5C9;
  font-size: 12px;
  padding-left: 40px
}
.footLi {
  margin: 0 40px
}
.liList {
  margin:10px 0;
  font-size: 12px;
  color: #C5C5C9;
  cursor: pointer
}
.liList:hover {
  color: #fff
}
.el-dropdown-menu__item {
  min-width: 157px !important
}
</style>   